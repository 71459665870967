import React, { useState, useEffect } from "react";
import "../css/Contact.css";

export default function Contact() {
  document.title = "Contact Us | Space Technology Students' Society";

  const [style, setStyle] = useState("contact-modal");

  const [checker, setChecker] = useState(0);

  const changeStyleOnce = () => {
    setStyle("contact-modal contact-modal-active");
    setChecker(checker + 1);
  };

  useEffect(() => {
    changeStyleOnce();
  }, []);

  return (
    <div>
      <div className="contact-principal">
        <div className="contact-central">
          <div className={style}>
            <div className="contact-photo">
              <div className="contact-img-back">
                <div className="contact-welcome-title">
                  <p className="contact-us">Contact Us!</p>
                  <p className="description">
                    Please get in touch with us, in case you have any queries or
                    questions.
                  </p>
                </div>

                <div className="contact-info">
                  <p className="phone-number">
                    <i className="fa fa-solid fa-phone"></i>
                    <a href="tel:+917078767360">+91 70787 67360</a>
                  </p>
                  <p className="email">
                    <i className="fa fa-solid fa-envelope"></i>
                    <a href="mailto:spats.iitkgp@gmail.com">
                      spats.iitkgp@gmail.com
                    </a>
                  </p>
                  <div className="address">
                    <i className="fa fa-location-arrow location-icon"></i>
                    <div className="address-div">
                      <a style={{ display: 'block' }} className="contact-loaction-link" href="https://goo.gl/maps/5yYR9fhq1wkAHbiE8" target="_blank">Kalpana Chawla Space Technology Cell, IIT Kharagpur</a>
                    </div>
                  </div>
                  <div className="social-media-links">
                    <a href="https://www.facebook.com/spAts.iitkgp/" target="_blank">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/spats.nssc.iitkgp/" target="_blank">
                      <i className="fa fa-instagram"></i>
                    </a>
                    <a
                      href="https://in.linkedin.com/company/spats"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="screen-background">
                {/* <span className="screen-background-shape screen-background-shape4"></span> */}
                <span className="screen-background-shape screen-background-shape3"></span>
                {/* <span className="screen-background-shape screen-background-shape2"></span> */}
                <span className="screen-background-shape screen-background-shape1"></span>
              </div>
            </div>
            <div className="contact-text-ingredients">
              <div className="contact-over-hidden">
                <div className="contact-tabs">
                  <p>SEND US YOUR QUERY!</p>
                </div>

                <div className="contact-text-det-preparation">
                  <div className="contact-title-preparation">
                    <p>NAME AND CONTACT DETAILS</p>
                  </div>
                  <form action="https://formsubmit.co/spats.iitkgp@gmail.com" method="POST" className="contact-info-preparation">
                    <div>
                      <input type="text" name="name" id="" placeholder="First Name" required />
                      <input type="email" name="email" id="" placeholder="Email" required />
                      <input type="tel" id="" name="tel" pattern="[0-9]{10}" maxLength={10} placeholder="Phone No." required />
                      <p className="write-your-query">WRITE YOUR QUERY</p>
                      <textarea
                        placeholder="Write your message"
                        name="query"
                        cols="30"
                        rows="5"
                      ></textarea>
                      <input type="submit" className="submit-button" />
                      <input type="hidden" name="_next" value="http://spats.co.in/contact"></input>
                      <input type="hidden" name="_captcha" value="false"></input>
                      <input type="hidden" name="_template" value="table"></input>
                      <input type="hidden" name="_autoresponse" value="Thanks for reaching out!Your message was received!"></input>
                      <input type="hidden" name="_subject" value="New submission!"></input>
                    </div>
                  </form>

                  <div className="contact-title-preparation">
                    <p className="contact-write-your-query">WRITE YOUR QUERY</p>
                  </div>
                  {/* <div className="contact-info-preparation write-your-query">
                    <div>
                      <textarea
                        placeholder="Write your message"
                        name="query"
                        cols="30"
                        rows="5"
                      ></textarea>
                    </div>
                  </div> */}

                  {/* <div className="contact-title-preparation">
                  </div> */}
                  {/* <div className="contact-info-preparation ">
                    <div>
                      <button className="submit-button" type="submit">SUBMIT</button>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="screen-background-query">
                {/* <span className="screen-background-shape screen-background-shape4"></span> */}
                {/* <span className="screen-background-shape screen-background-shape3"></span>		 */}
                <span className="screen-background-shape screen-background-shape5"></span>
                {/* <span className="screen-background-shape screen-background-shape2"></span> */}
                {/* <span className="screen-background-shape screen-background-shape1"></span> */}
              </div>
              {/* <img src="/img/contact/rocket-nobg.png" alt="" className="rocket-image"/> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
