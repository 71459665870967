import { useNavigate } from "react-router-dom";

function RelatedBlogsCard(props) {
  const post = props.post;
  const showDate = props.showDate != undefined ? props.showDate : true;

  const navigate = useNavigate();
  const nav = () => {
    navigate("/blog/post/" + post.slug);
  };

  return (
    <>
      <div className="blog-post-related">
        <img src={post.image} onClick={nav} />
        <div className="blog-post-related-details">
          <p className="title" onClick={nav}>
            {post.title}
          </p>
          {showDate && (
            <p className="date">
              <i className="fa fa-calendar" aria-hidden="true" /> {post.date}
            </p>
          )}
        </div>
      </div>
      {props.line && <hr />}
    </>
  );
}

export default RelatedBlogsCard;
