export const sliderData = [
    {
      image: "/img/events/spatsimage1.png",
      // heading: "Slide One",
      // desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "/img/events/spatsimage2.png",
      // heading: "Slide Two",
      // desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
    {
      image: "/img/events/spatsimage3.png",
      // heading: "Slide Three",
      // desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
    },
  ];