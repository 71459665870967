export const sliderData1 = [
  {
    image: "/img/events/fresher quiz(1).jpg",
    // heading: "Slide One",
    // desc: "This is the description of slide one Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  },
  {
    image: "/img/events/fresher quiz(2).jpg",
    // heading: "Slide Two",
    // desc: "This is the description of slide two Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  },
  {
    image: "/img/events/fresher quiz(3).jpg",
    // heading: "Slide Three",
    // desc: "This is the description of slide three Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi quos quas, voluptatum nesciunt illum exercitationem.",
  },
];