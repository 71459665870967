// src/data.js
export const reviews = [
    {
      id: 1,
      title: 'Exploring the Cosmos',
      description: 'A journey through the stars.',
      image: 'https://example.com/image1.jpg',
      fullContent: 'This is the full content of the review about exploring the cosmos...'
    },
    // Add more reviews as needed
  ];
  