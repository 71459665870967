import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../firebaseConfig";
import { ref, onValue, set } from "firebase/database";
import UpdatesCard from "../components/UpdatesCard";
import "../css/LatestUpdates.css";
import Loader from "../components/Loader";

export default function LatestUpdates(props) {
  document.title = "Latest Updates | Space Technology Students' Society";

  const [isLoading, setIsLoading] = useState(false);

  const handleMoreButtonClick = () => {
    let addnum = flexDivider > 2 ? flexDivider * 2 : 6;
    if (numberOfUpdates + addnum <= latestUpdates.length) {
      setNumberOfUpdates(numberOfUpdates + addnum);
    } else {
      setNumberOfUpdates(latestUpdates.length);
    }
  };

  const divideFlexarr = (width) => {
    if (width > 1200) {
      return 4;
    } else if (width > 850) {
      return 3;
    } else if (width > 600) {
      return 2;
    } else {
      return 1;
    }
  };

  const toggleModal = (data) => {
    setModalVisible(!modalVisisble);
    setModalContent(data);
  };

  const [latestUpdates, setLatestUpdates] = useState([]);

  let [flexArr, setFlexArr] = useState([
    [0, 3, 6],
    [1, 4, 7],
    [2, 8, 9],
  ]);
  const [flexDivider, setFlexDivider] = useState(
    divideFlexarr(window.innerWidth)
  );
  const [numberOfUpdates, setNumberOfUpdates] = useState(flexDivider * 3);
  const [modalVisisble, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  useEffect(() => {
    setIsLoading(true);
    const query = ref(db, "latest");
    return onValue(query, (snapshot) => {
      let data = snapshot.val();
      if (snapshot.exists()) {
        data.forEach((element, id) => {
          data[id] = {
            ...element,
            id: id,
          };
        });
        data = data.filter((val) => val.id != 0); //hide sample post
        data.sort((a, b) => (a.date > b.date ? 1 : -1));
        setLatestUpdates(data);
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setFlexDivider(divideFlexarr(window.innerWidth));
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let flexarr1 = [[], [], [], []];
    let k = 0;
    for (k = 0; flexDivider * k < numberOfUpdates; k++) {
      for (let j = 0; j < flexDivider; j++) {
        flexarr1[j].push(flexDivider * k + j);
      }
    }
    setFlexArr(flexarr1);
  }, [latestUpdates, numberOfUpdates, flexDivider]);

  return (
    <div className="latest-updates-container">
      <div className="latest-updates-title">
        <b>Latest Updates</b>
        {props.user ? (
          <Link to={"/latest/new/" + (latestUpdates.length + 1)}>
            <button>New Update</button>
          </Link>
        ) : (
          <></>
        )}
      </div>
      <div className="latest-updates-content">
        {isLoading ? (
          <Loader />
        ) : (
          flexArr.map((val, index) => (
            <div
              className={`latest-updates-subflex${index + 1}`}
              key={index + 1}
            >
              {val.map((valinner, ind, selfarr) => {
                return (
                  valinner < latestUpdates.length && (
                    <UpdatesCard
                      data={latestUpdates[latestUpdates.length - 1 - valinner]}
                      key={valinner}
                      isending={selfarr.length - ind == 1 && ind > 2 ? 1 : 0}
                      setModal={toggleModal}
                    />
                  )
                );
              })}
            </div>
          ))
        )}
      </div>
      <button
        className="more-updates-button"
        onClick={() => handleMoreButtonClick()}
        style={{
          display: numberOfUpdates < latestUpdates.length ? "block" : "none",
        }}
      >
        <i className="fa fa-angle-double-down" aria-hidden="true" />
        More Updates
        <i className="fa fa-angle-double-down" aria-hidden="true" />
      </button>
      <div
        className="latest-updates-modal-holder"
        style={{ display: modalVisisble ? "flex" : "none" }}
      >
        <div className="latest-updates-modal">
          <div className="latest-updates-modal-inner">
            <div className="latest-updates-modal-title">
              {modalContent.title}
            </div>
            <div className="latest-updates-modal-content">
              <img
                className="latest-updates-modal-img"
                src={modalContent.img}
              />
              <p>{modalContent.content}</p>
              <br />
              <div>
                <a href={modalContent.link} target="_blank">
                  Read Article
                </a>
                {props.user && (
                  <>
                    &nbsp;&nbsp;
                    <Link to={"/latest/edit/" + modalContent.id}>Edit</Link>
                  </>
                )}
              </div>
              <br />
            </div>
            <div className="latest-updates-modal-footer">
              {modalContent.date}
            </div>
            <div
              className="latest-updates-modal-button"
              onClick={() => toggleModal({})}
            >
              <i className="fa fa-times" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
