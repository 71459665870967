import React from "react";
import "../css/Stac1.css";
import { FaRocket } from "react-icons/fa";
import { MdOutlineQuiz } from "react-icons/md";
import { RiPresentationFill } from "react-icons/ri";
import { PiMathOperationsFill } from "react-icons/pi";
import { FaRobot } from "react-icons/fa6";
import { IoCarSportOutline } from "react-icons/io5";
import { FaCameraRetro } from "react-icons/fa";
import { DiGoogleAnalytics } from "react-icons/di";
import { LiaCertificateSolid } from "react-icons/lia";
import { GiPodiumWinner } from "react-icons/gi";
import { FaQuestion } from "react-icons/fa";
import { FaUniversity } from "react-icons/fa";

const Stac1 = () => {
  return (
    <>
      <div className="stac_container">
        <div className="stac_contentLeft">
          <div className="stac_row">
            <div className="stac_imgWrapper">
              <img
                src="https://images.unsplash.com/photo-1517976487492-5750f3195933?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt=""
              />
            </div>

            <div className="stac_imgWrapper">
              <img
                src="https://media.istockphoto.com/id/157639696/photo/purple-space-stars.webp?b=1&s=612x612&w=0&k=20&c=qISUct-nIGiiONJlfAevgzRWLDMb7CCLIkcyOD4NxZw="
                alt=""
              />
            </div>
            <div className="stac_imgWrapper">
              <img
                src="https://images.unsplash.com/photo-1701014159141-639d07c4eba4?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fHBsYW5ldHN8ZW58MHx8MHx8fDA%3D"
                alt=""
              />
            </div>
            <div className="stac_imgWrapper">
              <img
                src="https://media.istockphoto.com/id/1152464442/photo/i-offer-you-the-stars.webp?b=1&s=612x612&w=0&k=20&c=-WCqFLZ2B4NAVwjkLs9rxIlIMb0fdp19RyzoJIbtCgY="
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="stac_contentRight">
          <div className="stac_content">
            <h4>Welcome To</h4>
            <h2>Space Technology Awarness Camp</h2>
            <p>
              For Students outside of the KGP campus, We organise Space
              Technology Awareness Camp, or STAC for short. These interactive
              camps are conducted on their campus grounds which provides them an
              unparalleled opportunity to launch into space, through hands-on
              activities like water rocketry workshops, space quizzes and astro
              presentations. We aim to inspire and empower students from all
              backgrounds to embrace the wonders of space and technology,
              motivating a new generation of innovators and explorers.
            </p>
            <a href="https://forms.gle/sqjb8tHsYJJLAPJh7">
              Register for your college
            </a>
          </div>
        </div>
      </div>

      <section className="stac_hero_container">
        <h2>Our Events</h2>
        <div className="stac_hero_row">
          <div className="stac_hero_service">
            <FaRocket size={40} />
            <h3>Lift-off</h3>
            <p>
              Join us at 'Lift Off' to design, launch your own water rocket, and
              explore the excitement of flight and teamwork!
            </p>
          </div>
          <div className="stac_hero_service">
            <MdOutlineQuiz size={40} />
            <h3>Space Quiz</h3>
            <p>
              Test your cosmic knowledge at the Space Quiz. Open to all,
              covering astronomy basics to space advances!
            </p>
          </div>
          <div className="stac_hero_service">
            <FaCameraRetro size={40} />
            <h3>Astro-Presentation</h3>
            <p>
              Join us for a captivating 3D simulation of the Universe Sandbox
              and learn about cosmic phenomena in an interactive way.
            </p>
          </div>

          <div className="stac_hero_service">
            <FaRobot size={40} />
            <h3>Line following Bot</h3>
            <p>
              Experience the thrill of guiding your bot through complex lines!
              Design a line following bot and see it navigate efficiently
            </p>
          </div>
        </div>
      </section>

      <section className="stac_hero_section_section">
        <h2>Opportunities</h2>
        <div className="stac_hero_section_row">
          <div className="stac_hero_section_column">
            <div className="stac_hero_section_card">
              <div className="stac_hero_section_icon-wrapper">
                <LiaCertificateSolid size={40} />
              </div>
              <h3>Direct Entry at NSSC</h3>
              <p>
                There is a chance to get direct entry to the National Students'
                Space Challenge (NSSC) 2024 for the top participants.
              </p>
            </div>
          </div>
          <div className="stac_hero_section_column">
            <div className="stac_hero_section_card">
              <div className="stac_hero_section_icon-wrapper">
                <GiPodiumWinner size={40} />
              </div>
              <h3>Merchandise and Certificates</h3>
              <p>
                The top participants will receive certificates and exclusive
                NSSC merchandise as a token of appreciation.
              </p>
            </div>
          </div>
          <div className="stac_hero_section_column">
            <div className="stac_hero_section_card">
              <div className="stac_hero_section_icon-wrapper">
                <FaQuestion size={40} />
              </div>
              <h3>Become a Student Ambassador</h3>
              <p>
                The top participants will get an opportunity to become a Student
                Ambassador for the NSSC and represent their college.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="stac_footer_section">
        <h3>Previous STACs</h3>
        <div className="stac_footer_services-grid">
          <div className="stac_footer_service stac_footer_service1">
            <FaUniversity size={40} />
            <h4>Netaji Subhas University, Jamshedpur</h4>
            <p>
              An offline STAC was held at NSU, Jamshedpur. Participants engaged
              in a space quiz, astro-presentation, and lift-off demonstration.
              All participants received certificates, with special recognition
              given to the winners. The event was a resounding success,
              fostering interest in space exploration and science.
            </p>
            {/* <a href="#" className="stac_footer_cta">
                            Read More <span className="ti-angle-right"></span>
                        </a> */}
          </div>

          <div className="stac_footer_service stac_footer_service2">
            <FaUniversity size={40} />
            <h4>LNMIT Jaipur</h4>
            <p>
              A captivating STAC event was held at LNMIT Jaipur, featuring a
              space quiz, an informative astro-presentation, and lively
              discussions. Students actively engaged in thought-provoking Q&A
              sessions, sharing their insights and curiosity about the cosmos.
              All participants were awarded certificates, with exceptional
              performers receiving special recognition.
            </p>
            {/* <a href="#" className="stac_footer_cta">
                            Read More <span className="ti-angle-right"></span>
                        </a> */}
          </div>

          <div className="stac_footer_service stac_footer_service3">
            <FaUniversity size={40} />
            <h4>Future Institute of Technology, Kolkata</h4>
            <p>
              A hands-on STAC event was held at FIT, Kolkata. Students designed
              and built their own water rockets using everyday materials. They
              participated in a space quiz competition and an
              astro-presentation. All participants received certificates, with
              special recognition for the winners and top performers. The event
              was a great success.
            </p>
            {/* <a href="#" className="stac_footer_cta">
                            Read more <span className="ti-angle-right"></span>
                        </a> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Stac1;
