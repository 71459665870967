import { Link, useParams } from "react-router-dom";
import "../css/Blog.css";
import BlogCard from "../components/BlogCard";
import { useState, useEffect, useRef } from "react";

import { db } from "../firebaseConfig";
import { onValue, ref, set } from "firebase/database";
import RelatedBlogsCard from "../components/RelatedBlogsCard";
import Loader from "../components/Loader";

export default function Blog(props) {
  document.title = "Blog | Space Technology Students' Society";

  const [blog, updateBlog] = useState();
  const [posts, updatePosts] = useState([]);
  const [topPosts, updateTopPosts] = useState([]);
  const { tag } = useParams();
  const [search, updateSearch] = useState("");
  const sidebarRef = useRef([]);
  const [top, setTop] = useState(0);
  const [displayCount, setDisplayCount] = useState(6);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const query = ref(db, "blog");
    return onValue(query, (snapshot) => {
      let data = snapshot.val();
      if (snapshot.exists()) {
        data.tags = data.tags.map((item) => {
          let count = 0;
          data.posts.forEach((post) => {
            if (post.tags.includes(item)) count++;
          });
          return {
            name: item,
            count: count,
          };
        });
        updateBlog(data);
      }
    });
  }, []);

  useEffect(() => {
    if (!blog) return;
    var posts = blog.posts
      .filter((post, id) => {
        if (post.slug === "sample-post" || post.slug.includes("post-")) {
          return false; //hide sample post
        }
        if (!tag || post.tags.includes(tag)) return true;
        else return false;
      })
      .sort((a, b) => {
        let da = new Date(a.date),
          db = new Date(b.date);
        return db - da;
      });

    // var topPosts = blog.posts.sort((a, b) => b.views - a.views).slice(0, 7);
    var topPosts = blog.posts
      .sort((a, b) => {
        let da = new Date(a.date),
          db = new Date(b.date);
        return da - db;
      })
      .slice(0, 7);
    updateTopPosts(topPosts);

    updatePosts(posts);
  }, [blog, tag]);

  // useEffect(() => {
  //   var prevScrollpos = window.scrollY;
  //   const onscroll = () => {
  //     var currentScrollPos = window.scrollY;
  //     let blogTagList = document.getElementById("blog-tag-list");
  //     if (blogTagList == null) return;
  //     // to check if the navbar is on landing page or on other pages
  //     if (prevScrollpos > currentScrollPos) {
  //       blogTagList.style.top = "120px";
  //     } else {
  //       blogTagList.style.top = "20px";
  //     }
  //     prevScrollpos = currentScrollPos;
  //   };
  //   window.addEventListener("scroll", onscroll);

  //   return () => {
  //     window.removeEventListener("scroll", onscroll);
  //   };
  // }, []);

  useEffect(() => {
    // set top value of sidebar
    if (sidebarRef.current) {
      setTop(
        sidebarRef.current.offsetHeight > window.innerHeight
          ? window.innerHeight - sidebarRef.current.offsetHeight - 10
          : 0
      );
    }
  }, [topPosts]);

  const titleString = (string) => {
    return string
      .split("-")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(" ");
  };

  useEffect(() => {
    const handleMobileView = () => {
      if (window.innerWidth < 768) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    };

    console.log(mobileView);
    handleMobileView();

    window.addEventListener("resize", handleMobileView);

    return () => {
      window.removeEventListener("resize", handleMobileView);
    };
  }, []);

  const topReads = (
    <div className="blog-top-reads">
      <h1>Top Reads</h1>
      <hr />
      <div className="blog-top-reads-posts">
        {topPosts.map((val, ind) => (
          <RelatedBlogsCard
            post={val}
            line={ind + 1 != topPosts.length}
            key={"top-reads" + ind}
            nav={() => nav(val.slug)}
            showDate={false}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className="blog-container">
      <div className="blog-header-container">
        <b>Blog</b>
        {props.user ? (
          <Link to="/blog/post/new">
            <button className="blog-new-button">New Post</button>
          </Link>
        ) : null}
      </div>
      {blog ? (
        <div className="blog-content">
          <div
            className="blog-sidebar"
            ref={sidebarRef}
            style={{
              top: `${top}px`,
            }}
          >
            <div className="blog-tag-list" id="blog-tag-list">
              <h1>Explore Topics</h1>
              <div className="input-wrap">
                <input
                  type="text"
                  name="searchTag"
                  placeholder="Search all topics"
                  value={search}
                  onChange={(e) => {
                    updateSearch(e.target.value);
                  }}
                />
                <i className="fa fa-search" aria-hidden="true" />
              </div>
              <br />
              <div className="blog-tags-container">
                {blog.tags
                  .filter((item) => {
                    return item.name
                      .toLowerCase()
                      .includes(search.toLowerCase());
                  })
                  .sort((a, b) => {
                    if (a.name === tag) return -1;
                    else if (b.name === tag) return 1;
                    else return b.count - a.count;
                  })
                  .slice(0, 10)
                  .map((item, id) => (
                    <Link
                      key={id}
                      to={item.name === tag ? "/blog" : "/blog/" + item.name}
                      className={
                        item.name === tag
                          ? "blog-tag-button blog-active-tag"
                          : "blog-tag-button"
                      }
                    >
                      {titleString(item.name)}
                      {/* <span>{item.count ? `(${item.count})` : ""}</span> */}
                    </Link>
                  ))}
                {props.user ? (
                  <input
                    type="text"
                    name="newTag"
                    placeholder="New tag"
                    onChange={(e) => {
                      let s = e.target.value;
                      s = s
                        .toLowerCase()
                        .replace(/ /g, "-")
                        .replace(/[^\w-]+/g, "");
                      while (s.includes("--")) s = s.replace("--", "-");
                      while (s.startsWith("-")) s = s.substring(1);
                      e.target.value = s;
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        let tag = event.target.value;
                        if (!tag) return;

                        if (tag[tag.length - 1] === "-") {
                          alert("Please remove trailing hyphen");
                          return;
                        }

                        let flag = true;
                        blog.tags.forEach((item) => {
                          if (item.name === tag) flag = false;
                        });
                        if (!flag) {
                          alert("Tag already exists");
                          return;
                        }

                        blog.tags.push({
                          name: tag,
                          count: 0,
                        });
                        set(
                          ref(db, "blog/tags"),
                          blog.tags.map((item) => item.name)
                        )
                          .then(() => {
                            alert("new tag added");
                          })
                          .catch((error) => {
                            alert("error while adding new tag");
                            console.log(error);
                          });
                        event.target.value = "";
                      }
                    }}
                  />
                ) : null}
              </div>
            </div>
            {!mobileView ? topReads : null}
          </div>

          <div className="blog-cards-list">
            <h1>
              {tag ? "Showing posts on topic: " : ""}
              <span>{tag ? titleString(tag) : ""}</span>
            </h1>
            {posts.length !== 0 ? (
              posts
                .slice(0, displayCount)
                .map((post, id) => <BlogCard post={post} key={id} />)
            ) : (
              <h1>No posts found</h1>
            )}
            {posts.length > displayCount && (
              <button
                className="more-updates-button"
                onClick={() => setDisplayCount(displayCount + 4)}
              >
                <i className="fa fa-angle-double-down" aria-hidden="true" />
                More Blogs
                <i className="fa fa-angle-double-down" aria-hidden="true" />
              </button>
            )}
          </div>
          {mobileView ? topReads : null}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
