import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkhM3B8IYRfkeXvw9TLkIxkxLm7TWCr5Q",
  authDomain: "spats-2023.firebaseapp.com",
  projectId: "spats-2023",
  storageBucket: "spats-2023.appspot.com",
  messagingSenderId: "1013826431189",
  appId: "1:1013826431189:web:16b3d87cb1d2c0e9751a5c",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(
  app,
  "https://spats-2023-default-rtdb.asia-southeast1.firebasedatabase.app/"
);
