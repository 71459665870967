import LandingPage_Section1 from "../components/LandingPage/LandingPage_Section1";
import LandingPage_Section2 from "../components/LandingPage/LandingPage_Section2";

export default function LandingPage() {
  return (
    <div className="landingpage">
      <LandingPage_Section1 />
      <LandingPage_Section2 />
    </div>
  );
}
