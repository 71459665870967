import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../css/LandingPage_Section2.css";

import { db } from "../../firebaseConfig";
import { onValue, ref } from "firebase/database";

export default function LandingPage_Section2() {
  let sizear2 = [1, 2, 3];
  let sizear1 = [1, 2, 3, 4, 5, 6];
  let [blogs, updateBlogs] = useState([]);
  let [latestUpdates, setLatestUpdates] = useState([]);

  const Content = ({ data, num, linkto }) => (
    <Link to={linkto} className="landingpage-section2-content">
      <div className="landingpage-section2-content-num">
        <b>{"0" + num}</b>
        <img src={data.img ? data.img : data.image} />
      </div>
      <div className="landingpage-section2-content-text">
        <p>{data.title}</p>
        <p>{"Posted on " + data.date}</p>
      </div>
    </Link>
  );

  useEffect(() => {
    const query = ref(db, "blog");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      if (snapshot.exists()) {
        let blogtemp = [];
        data.posts
          .filter((val, id) => id != 0) //hide sample post
          .sort((a, b) => {
            let da = new Date(a.date),
              db = new Date(b.date);
            return da - db;
          })
          .map((val) => {
            blogtemp.push(val);
          });
        updateBlogs(blogtemp);
      }
    });
  }, []);

  useEffect(() => {
    const query = ref(db, "latest");
    return onValue(query, (snapshot) => {
      let data = snapshot.val();
      if (snapshot.exists()) {
        data = data.filter((val, id) => id != 0); //hide sample post
        data.sort((a, b) => (a.date > b.date ? 1 : -1));
        setLatestUpdates(data);
      }
    });
  }, []);

  return (
    <div className="landingpage-section2">
      <div className={"landingpage-blogs"}>
        <div className="landingpage-section2-headers">
          <b> Latest Blogs </b>
          <Link to="/blog">
            <p> See more.. </p>
          </Link>
        </div>
        <div className="landingpage-section2-content-holder">
          {sizear1.map(
            (val, id) =>
              val <= blogs.length && (
                <Content
                  data={blogs[blogs.length - val]}
                  num={val}
                  key={id}
                  linkto={"/blog/post/" + blogs[blogs.length - val].slug}
                />
              )
          )}
        </div>
      </div>
      <div className="landingpage-latestupdates">
        <div className="landingpage-section2-headers">
          <b> New Updates </b>
          <Link to="/latest">
            <p> See more.. </p>
          </Link>
        </div>
        <div className="landingpage-section2-content-holder">
          {sizear2.map(
            (val, id) =>
              val <= latestUpdates.length && (
                <Content
                  data={latestUpdates[latestUpdates.length - val]}
                  num={val}
                  key={id}
                  linkto={"/latest"}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
}
