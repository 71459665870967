import React, { useEffect, useState } from "react";
import { sliderData } from "./Slider-data";
import { sliderData1 } from "./Slider-data1";
import { sliderData2 } from "./Slider-data2";

const Slider2 = () => {
  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % sliderData.length);
  };

  const previousImage = () => {
    setCurrentImage((prevImage) =>
      prevImage === 0 ? sliderData.length - 1 : prevImage - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="Events2">
      <div className="events-content1">
        <div className="text-box1">
          <h3>SKY-GAZING</h3>
          <p>
            Knowing about anything starts from observing it over a long period
            of time, keeping records and analysing the changes. The good thing
            is about this is, much of it remains the same, at least through a
            lifespan of a human. Many of the discoveries in space were only
            observations made for a looooong period of time. Basically, we try
            to show people the hidden beauty of the space by organizing a sky
            gazing session once every semester. For that we have an 8 inch
            Newtonian reflecting type telescope with tracking device, which
            helps to track the celestial body along its motion.
          </p>
        </div>
        <div className="slider1">
          <button className="arrow arrow-left" onClick={previousImage}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
          {sliderData.map((data, index) => (
            <img
              key={index}
              className={`image-events ${
                index === currentImage ? "" : "fade-out"
              }`}
              src={data.image}
              alt={`Image ${index + 1}`}
            />
          ))}
          <button className="arrow arrow-right" onClick={nextImage}>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>

      {/* <div className="events-content2">
        <div className="text-box2" data-aos="fade-right">
          <h3>Data Analytics</h3>
          <p>
          The data analytics competition held by the Space Technology Students' Society (spAts) provides an exhilarating platform for students to showcase their prowess in harnessing the power of data. Participants delve into complex datasets derived from space missions, applying analytical techniques to uncover valuable insights. This competition challenges students to employ their skills in statistical analysis, machine learning, and visualization to solve real-world problems in the realm of space technology. Through this competition, spAts fosters a culture of data-driven innovation, inspiring young minds to explore the vast possibilities of data analytics in revolutionizing space science and engineering.
          </p>
        </div>
        <div className="slider2">
          <button className="arrow arrow-black arrow-left" onClick={previousImage}>
            <i className="fa fa-arrow-left" aria-hidden="true"></i>

          </button>
          {sliderData.map((data, index) => (
            <img
              key={index}
              className={`image-events ${
                index === currentImage ? "" : "fade-out"
              }`}
              src={data.image}
              alt={`Image ${index + 1}`}
            />
          ))}
          <button className="arrow arrow-right arrow-black" onClick={nextImage}>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>

          </button>
        </div>
      </div>

      <div className="events-content1">
        <div className="text-box1" data-aos="fade-right">
          <h3>Space Quiz</h3>
          <p>
          The space quiz competition organized by the Space Technology Students' Society (spAts) is a thrilling event that puts participants' knowledge of the cosmos to the test. This captivating competition brings together space enthusiasts from various backgrounds to showcase their expertise in astronomy, astrophysics, space exploration, and beyond. With thought-provoking questions ranging from celestial bodies to space missions, participants engage in a battle of wits and demonstrate their passion for all things space-related. The space quiz competition by spAts fuels intellectual curiosity, fosters a deeper understanding of our universe, and encourages participants to stay at the forefront of space science and technology.
          </p>
        </div>

        <div className="slider1">
          <button
            className="arrow arrow-left"
            onClick={previousImage}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
          {sliderData.map((data, index) => (
            <img
              key={index}
              className={`image-events ${
                index === currentImage ? "" : "fade-out"
              }`}
              src={data.image}
              alt={`Image ${index + 1}`}
            />
          ))}
          <button className="arrow arrow-right" onClick={nextImage}>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
      </div> */}

      <div className="events-content2">
        <div className="text-box2" data-aos="fade-right">
          <h3>Freshers Quiz</h3>
          <p>
            The freshers quiz competition organized by the Space Technology
            Students' Society (spAts) sets the stage for new students to ignite
            their curiosity and passion for space exploration. This thrilling
            event brings together enthusiastic participants to test their
            knowledge in a dynamic quiz format. Covering various aspects of
            space science and technology, the competition challenges freshers to
            think critically, recall facts, and engage in friendly competition.
            With each question, participants gain insights into the wonders of
            the universe, while fostering a sense of camaraderie among
            like-minded individuals. The freshers quiz competition by spAts
            paves the way for future space enthusiasts, nurturing a community of
            knowledge seekers and dreamers.
          </p>
        </div>
        <div className="slider2">
          <button
            className="arrow arrow-left arrow-black"
            onClick={previousImage}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
          {sliderData1.map((data, index) => (
            <img
              key={index}
              className={`image-events ${
                index === currentImage ? "" : "fade-out"
              }`}
              src={data.image}
              alt={`Image ${index + 1}`}
            />
          ))}
          <button className="arrow arrow-right arrow-black" onClick={nextImage}>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      
      <div className="events-content1">
        <div className="text-box1" data-aos="fade-right">
          <h3>Space Technology Awareness Camp</h3>
          <p>
          {/* STAC (Space Technology Awareness Camp) is a captivating and inspiring event organized by the Space Technology Club at our university. With a strong passion for space exploration and astrophysics, the club members embark on a remarkable journey, traveling across the nation to create awareness about the wonders of the cosmos and other fascinating space-related topics. STAC serves as a platform to engage students, educators, and the general public, fostering an understanding and appreciation for the advancements and possibilities of space technology. Through interactive workshops, captivating presentations, and hands-on activities, the event aims to ignite curiosity, inspire the next generation of space enthusiasts, and promote the importance of space exploration for the betterment of our society. STAC is an unforgettable experience that empowers individuals to think beyond the boundaries of Earth and envision a future where space technology plays a crucial role in our daily lives. */}
          STAC (Space Technology Awareness Camp) is a captivating and inspiring event organized by the Space Technology Club. STAC serves as a platform to engage students, educators, and the general public, fostering an understanding and appreciation for the advancements and possibilities of space technology. Through interactive workshops, captivating presentations, and hands-on activities, the event aims to ignite curiosity, inspire the next generation of space enthusiasts, and promote the importance of space exploration for the betterment of our society. 
          </p>
        </div>

        <div className="slider1">
          <button
            className="arrow arrow-left"
            onClick={previousImage}
          >
            <i className="fa fa-arrow-left" aria-hidden="true"></i>
          </button>
          {sliderData2.map((data, index) => (
            <img
              key={index}
              className={`image-events ${
                index === currentImage ? "" : "fade-out"
              }`}
              src={data.image}
              alt={`Image ${index + 1}`}
            />
          ))}
          <button className="arrow arrow-right" onClick={nextImage}>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      
    </div>
  );
};

export default Slider2;
