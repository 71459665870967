import React from "react";
import "../css/UpdatesCard.css";

export default function UpdatesCard({ data, isending, setModal }) {
  const lastWord = (content) => {
    let i = 2;
    // while (content.substr(content.length - i, 1) != " ") i++;
    return content.slice(0, content.length - i);
  };

  return (
    <div className={`updates-card ${isending ? "updates-card-new" : ""}`}>
      <div className="updates-card-img-container">
        <img src={data.img} alt={data.title} />
      </div>
      <div className="updates-card-items-container">
        <div className="updates-card-dummy">
          <div className="updates-card-description-container">
            <div className="updates-card-title-container">{data.title}</div>
            <div className="updates-card-content-container">
              {lastWord(data.content.slice(0, 180)) + "..."}
            </div>
            <div className="updates-card-date-container">
              <p>{data.date}</p>
            </div>
          </div>
          <div className="updates-card-button" onClick={() => setModal(data)}>
            Read More
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
  );
}
