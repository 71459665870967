import "../css/Footer.css";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  if (location.pathname === "/about") return null;

  return (
    <div className="footer_code">
      <div className="footer">
        <Link to="/">
          <img
            src="/img/logo-spats-footer.png"
            className="footer-logo"
            alt=""
          />
        </Link>
        <ul className="menu">
          <div>
            <li>
              <Link className="menu__link" to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className="menu__link" to="/events">
                Events
              </Link>
            </li>
          </div>
          <div>
            <li>
              <Link className="menu__link" to="/team">
                Team
              </Link>
            </li>
            <li>
              <Link className="menu__link" to="/latest">
                Updates
              </Link>
            </li>
            <li>
              <Link className="menu__link" to="/blog">
                Blog
              </Link>
            </li>
            <li>
              <Link className="menu__link" to="/contact">
                Contact
              </Link>
            </li>
          </div>
        </ul>

        <ul className="social_icon">
          <li className="social-icon__link">
            <a
              className="social-icon__link"
              href="https://www.facebook.com/spAts.iitkgp"
            >
              <i className="fa fa-facebook" aria-hidden="true"></i>
            </a>
          </li>
          <li className="social-icon__link">
            <a className="social-icon__link" href="#">
              <i className="fa fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
          <li className="social-icon__link">
            <a
              className="social-icon__link"
              href="https://www.linkedin.com/company/spats/"
            >
              <i className="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
          <li className="social-icon__link">
            <a
              className="social-icon__link"
              href="https://www.youtube.com/@spAtsiitkgp"
            >
              <i className="fa fa-youtube-play" aria-hidden="true"></i>
            </a>
          </li>
          <li className="social-icon__link">
            <a
              className="social-icon__link"
              href="https://twitter.com/spats_iitkgp"
            >
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="footer-copyright">
        {" "}
        &copy;Space Technology Students' Society{" "}
        <img
          src="https://cdn.pixabay.com/photo/2014/04/03/11/53/rocket-312455__340.png"
          width="40"
          className="rob"
          alt=""
        />
      </div>
    </div>
  );
}
