import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { db } from "../firebaseConfig";
import { ref, set, onValue } from "firebase/database";
import "../css/LatestForm.css";

export default function LatestForm({ user, action }) {
	const id = useParams().id;
	const navigate = useNavigate();

	let [data, setData] = useState();

	useEffect(() => {
		if (!user || !id) navigate("/latest", { replace: true });

		if (action === "Edit") {
			const query = ref(db, "latest/" + id);
			return onValue(query, (snapshot) => {
				const data = snapshot.val();
				if (snapshot.exists()) {
					setData(data);
				} else {
					alert("Post not found");
					navigate("/latest", { replace: true });
				}
			});
		} else if (action === "New") {
			let date = new Date();
			setData({
				img: "",
				title: "",
				content: "",
				date: `${date.getFullYear()}-${
					(date.getMonth() > 9 ? "" : "0") + (date.getMonth() + 1)
				}-${(date.getDate() > 9 ? "" : "0") + date.getDate()}`,
				link: "",
			});
		}
	}, []);

	const handleUpdate = (e) => {
		if (e.target.name === "img") {
			if (e.target.value.includes("drive.google.com/file/d/")) {
				fetch(e.target.value)
					.then((response) => response.text())
					.then((htmlContent) => {
						var storedHTML = htmlContent;
						var tempIframe = document.createElement("iframe");
						document.body.appendChild(tempIframe);
						tempIframe.contentDocument.write(storedHTML);
						tempIframe.contentDocument.close();

						tempIframe.onload = function () {
							var iframeDocument =
								tempIframe.contentDocument ||
								tempIframe.contentWindow.document;
							var imgTags =
								iframeDocument.getElementsByTagName("img");
							if (imgTags.length > 0) {
								var imgSource = imgTags[0].src;
								setData({
									...data,
									[e.target.name]: imgSource,
								});
							} else {
								alert("No image found in the provided link");
							}
							// tempIframe.style.display = "none";
							// document.body.removeChild(tempIframe);
						};
					})
					.catch((error) => {
						console.error("Error fetching content:", error);
					});
				//   const id = e.target.value.split("/")[5];
				//   e.target.value = "https://drive.google.com/uc?export=view&id=" + id;
			}
		}
		setData({
			...data,
			[e.target.name]: e.target.value,
		});
	};

	const formSubmit = (e) => {
		e.preventDefault();

		if (!confirm("Are you sure you want to save?")) {
			e.preventDefault();
			return;
		}

		//save to backend
		let loc = "latest/" + id;
		set(ref(db, loc), data)
			.then(() => {
				// console.log("saved to " + loc);
				navigate("/latest", { replace: true });
			})
			.catch((error) => {
				alert("error");
				console.log(error);
			});
	};

	return data ? (
		<form className="latest-form" onSubmit={formSubmit}>
			<div className="latest-form-title">{action} Update</div>
			<div className="latest-form-input-container">
				<label className="latest-form-input-label">Title</label>
				<input
					type="text"
					placeholder="Title"
					name="title"
					onChange={handleUpdate}
					value={data.title}
					required={true}
				/>
			</div>
			<div className="latest-form-input-container">
				<label className="latest-form-input-label">Image</label>
				<input
					type="text"
					placeholder="Image URL or google drive link"
					name="img"
					onChange={handleUpdate}
					value={data.img}
					required
				/>
			</div>
			<div className="latest-form-input-container">
				<label className="latest-form-input-label">Content</label>
				<textarea
					placeholder="Content"
					name="content"
					onChange={handleUpdate}
					value={data.content}
					required
				/>
			</div>
			<div className="latest-form-input-container">
				<label className="latest-form-input-label">Date</label>
				<input
					className="latest-form-date"
					type="date"
					name="date"
					onChange={handleUpdate}
					value={data.date}
					required
				/>
			</div>
			<div className="latest-form-input-container">
				<label className="latest-form-input-label">Read Article</label>
				<input
					type="text"
					placeholder="Article Link"
					name="link"
					onChange={handleUpdate}
					value={data.link}
					required
				/>
			</div>
			<div className="latest-form-footer">
				<Link to="/latest" className="latest-form-button">
					Cancel
				</Link>
				<button className="latest-form-button" type="submit">
					Save
				</button>
			</div>
		</form>
	) : (
		<h1>Loading...</h1>
	);
}
