import { Link } from "react-router-dom";
import "../css/PostDetails.css";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share";
import ImageUpload from "./ImageUpload";

export default function PostDetails(props) {
  const { blog, post, setPost } = props;
  const baseUrl = "https://spats.co.in/blog/post/";

  function handleTagClick(e, write, tag) {
    if (write) {
      e.preventDefault();
      const tags = post.tags;
      const index = tags.indexOf(tag);
      if (index > -1) {
        tags.splice(index, 1);
      } else {
        tags.push(tag);
      }
      setPost({
        ...post,
        tags: tags,
      });
    }
  }

  const handleChange = (e) => {
    if (e.target.name === "image") {
      if (e.target.value.includes("drive.google.com/file/d/")) {
        const id = e.target.value.split("/")[5];
        e.target.value =
          "https://drive.google.com/thumbnail?id=" + id + "&sz=w1000";
        setPost({
          ...post,
          [e.target.name]: e.target.value,
        });
      }

      //   const id = e.target.value.split("/")[5];
      //   e.target.value = "https://drive.google.com/uc?export=view&id=" + id;
    }

    if (e.target.name === "slug") {
      let s = e.target.value;
      s = s
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      while (s.includes("--")) s = s.replace("--", "-");
      while (s.startsWith("-")) s = s.substring(1);
      setPost({
        ...post,
        [e.target.name]: s,
      });
    } else {
      setPost({
        ...post,
        [e.target.name]: e.target.value,
      });
    }
  };

  const imageHandler = (id, imageUrl, fileIsValid) => {
    if (fileIsValid) {
      setPost({
        ...post,
        image: imageUrl,
      });
      console.log(imageUrl);
    }
  };

  return (
    <div className="blog-post-details-container">
      <div
        className="blog-post-details-background"
        style={{
          backgroundImage: "url(" + post.image + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      {props.write && (
        <div className="blog-post-image">
          {/* <img className="blog-post-image" src={post.image} />
          <input
            type="text"
            name="image"
            value={post.image}
            onChange={handleChange}
            placeholder="Image URL or Google Drive link"
          /> */}
          <ImageUpload
            id="image"
            center
            onInput={imageHandler}
            errorText="Please provide an image."
          />
        </div>
      )}
      <div className={"blog-post-details" + (props.write ? "" : " readonly")}>
        {props.new && (
          <>
            <div className="blog-input-label">
              <p>Slug</p>
              <input
                className="blog-post-slug"
                type="text"
                name="slug"
                value={post.slug}
                onChange={handleChange}
              />
            </div>
          </>
        )}
        <div className="blog-input-label">
          {props.write && <p>Title</p>}
          {props.write ? (
            <input
              className="blog-post-title"
              type="text"
              name="title"
              value={post.title}
              onChange={handleChange}
            />
          ) : (
            <div className="blog-post-title readonly">{post.title}</div>
          )}
        </div>
        {props.write && (
          <div className="blog-input-label">
            <p>Description</p>
            <textarea
              className={
                "blog-post-description" + (!props.write ? " readonly" : "")
              }
              type="text"
              name="description"
              value={post.description}
              onChange={handleChange}
              readOnly={!props.write}
            ></textarea>
          </div>
        )}
        <div className="blog-input-label">
          {props.write && <p>Date</p>}
          <input
            className={"blog-post-date" + (!props.write ? " readonly" : "")}
            type={props.write ? "date" : "text"}
            name="date"
            value={(props.write ? "" : "Posted on : ") + post.date}
            onChange={handleChange}
            readOnly={!props.write}
          />
        </div>
        <div className="blog-input-label">
          {props.write && <p>Tags</p>}
          <div>
            {blog.tags
              .filter((item) => {
                return props.write || post.tags.includes(item.name);
              })
              .sort((a, b) => b.count - a.count)
              .map((item, id) => (
                <Link
                  to={"/blog/" + item.name}
                  key={id}
                  className={
                    post.tags.includes(item.name)
                      ? "blog-tag-button blog-active-tag"
                      : "blog-tag-button"
                  }
                  onClick={(e) => {
                    handleTagClick(e, props.write, item.name);
                  }}
                >
                  {item.name}
                </Link>
              ))}
            {props.write && post.tags.length < 1 && (
              <p style={{ color: "red" }}>Select at least one tag!</p>
            )}
          </div>
        </div>
      </div>
      {!props.write && (
        <div className="blog-post-share">
          <div className="share-button facebook">
            <FacebookShareButton url={baseUrl + post.slug} quote={post.title}>
              <i className="fa fa-facebook" aria-hidden="true" />
              <span>Share on Facebook</span>
            </FacebookShareButton>
          </div>
          <div className="share-button twitter">
            <TwitterShareButton url={baseUrl + post.slug} title={post.title}>
              <i className="fa fa-twitter" aria-hidden="true" />
              <span>Share to Twitter</span>
            </TwitterShareButton>
          </div>
          <div className="share-button whatsapp">
            <WhatsappShareButton url={baseUrl + post.slug} title={post.title}>
              <i className="fa fa-whatsapp" aria-hidden="true" />
              <span>Share to Whatsapp</span>
            </WhatsappShareButton>
          </div>
          <div className="share-button telegram">
            <TelegramShareButton url={baseUrl + post.slug} title={post.title}>
              <i className="fa fa-telegram" aria-hidden="true" />
              <span>Share to Telegram</span>
            </TelegramShareButton>
          </div>
        </div>
      )}
    </div>
  );
}
