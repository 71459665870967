import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { set } from "firebase/database";

const ImageUpload = (props) => {
  const [file, setFile] = useState();
  const [isValid, setIsValid] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();
  const [isUploaded, setIsUploaded] = useState(false);

  const filePickRef = useRef();

  const storage = getStorage();

  useEffect(() => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const pickHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const pickImageHandler = () => {
    filePickRef.current.click();
  };

  const uploadImageHandler = async () => {
    const dateTime = giveCurrentDateTime();

    const storageRef = ref(
      storage,
      `files/${file.name + "       " + dateTime}`
    );

    // Create file metadata including the content type
    const metadata = {
      contentType: file.type,
    };

    // Upload the file in the bucket storage
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress
        //will implement progress bar later
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle error
        console.log("Error uploading file: ", error);
      },
      () => {
        // Handle successful upload
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setIsUploaded(true);
          console.log("File successfully uploaded.", downloadURL);
          props.onInput(props.id, downloadURL, isValid);
        });
      }
    );
  };

  const giveCurrentDateTime = () => {
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + " " + time;
    return dateTime;
  };

  return (
    <div className="form-control">
      <input
        type="file"
        style={{ display: "none" }}
        id={props.id}
        accept=".jpg,.png,.jpeg"
        onChange={pickHandler}
        ref={filePickRef}
      />
      <div className={`image-upload ${props.center && "center"}`}>
        <div className="image-upload__preview">
          {previewUrl && <img src={previewUrl} alt="Preview" />}
          {!previewUrl && <p> Please pick an image.</p>}
        </div>
        <button type="button" onClick={pickImageHandler}>
          PICK IMAGE
        </button>
        {previewUrl && !isUploaded && (
          <button onClick={uploadImageHandler}>UPLOAD</button>
        )}
        {isUploaded && <p>Image uploaded successfully!</p>}
        {!isValid && <p>{props.errorText}</p>}
      </div>
    </div>
  );
};

export default ImageUpload;
