import React from "react";
import ReviewCard from "../components/ReviewCard";
import { reviews } from "./data";
import "../css/Publication.css";
import ReviewCardSingle from "../components/ReviewCardSingle";

const Home = () => {
	return (
		<div>
			<div className="publication_header-complete">
				<div>
					<div className="publication_waviy">
						<div className="publication_main-inner">
							<span className="publication_i1">O</span>
							<span className="publication_i2">U</span>
							<span className="publication_i3">R</span>
							<span className="publication_i4">&nbsp;</span>{" "}
							{/* Added space here */}
							<span className="publication_i5">P</span>
							<span className="publication_i6">U</span>
							<span className="publication_i7">B</span>
							<span className="publication_i8">L</span>
							<span className="publication_i10">I</span>
							<span className="publication_i11">C</span>
							<span className="publication_i12">A</span>
							<span className="publication_i13">T</span>
							<span className="publication_i14">I</span>
							<span className="publication_i15">O</span>
							<span className="publication_i16">N</span>
							<span className="publication_i17">S</span>
						</div>
					</div>
					<div className="publication_lr-quote">
						"The literature review is the foundation of any worthwhile research
						project, providing the context and justification for the study's
						direction and significance."
					</div>
				</div>
				<div
					className="publication_lr-about-complete"
					style={{ display: "flex" }}
				>
					<div className="publication_about_literature_review">
						<div className="publication_lr-title">About</div>
						<div
							className="publication_lr-about"
							style={{ fontFamily: "monospace" }}
						>
							A literature review is a comprehensive summary and analysis of the
							existing research on a particular topic. It involves
							systematically identifying, evaluating, and synthesizing relevant
							scholarly articles, books, and other sources to provide an
							overview of current knowledge, identify gaps, and establish the
							context for new research. The literature review helps to frame the
							research question, demonstrate the significance of the study, and
							ensure that the research is built upon a solid foundation of
							existing work. It is an essential part of academic writing,
							providing credibility and grounding for the researcher’s own
							contributions.
						</div>
					</div>
					<div className="singlepdf">
						<ReviewCardSingle />
					</div>
				</div>
			</div>
			<div className="review_cards">
				{reviews.map((review) => (
					<ReviewCard key={review.id} review={review} />
				))}
			</div>
		</div>
	);
};

export default Home;
