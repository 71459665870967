import { useState } from "react";
import MemberCard from "../components/MemberCard";
import team from "../data/team.json";
import "../css/Team.css";

export default function Team() {
  document.title = "Team | Space Technology Students' Society";
  let [contentCateg, setContentCateg] = useState(1);

  let categories = [
    [0, "Steering committee", "5th Years", "4th Years"],
    [1, "Governor and G-Sec", "Governor", "General Secretaries"],
    [2, "Executive Member", "Senior Executives",""],
  ];

  const mapCategs = (categ) => {
    return (
      <div
        className={`team-page-cat ${
          contentCateg == categ[0] ? "team-page-cat-isactive" : ""
        }`}
        onClick={() => setContentCateg(categ[0])}
        key={categ[1]}
      >
        {categ[1]}
      </div>
    );
  };

  let content = (
    <>
      <div className="team-page-content-title">
        {categories[contentCateg][2]}
      </div>
      <div className="team-page-content-members">
        {team[categories[contentCateg][2].replace(" ", "-")].map((data) => (
          <MemberCard
            data={data}
            isSpecial={contentCateg == 1}
            key={data.cardinal}
          />
        ))}
      </div>
      <div className="team-page-content-title">
        {categories[contentCateg][3]}
      </div>
      <div className="team-page-content-members">
        {team[categories[contentCateg][3].replace(" ", "-")].map((data) => (
          <MemberCard
            data={data}
            isSpecial={contentCateg == 1}
            key={data.cardinal}
          />
        ))}
      </div>
    </>
  );

  return (
    <div className="team-page">
      <div className="team-page-header">
        <b>Our Team</b>
        <div className="team-page-cat-select">
          {categories.map((ele) => mapCategs(ele))}
        </div>
      </div>
      <div className="team-page-content">{content}</div>
    </div>
  );
}
