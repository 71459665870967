import React from "react";
import "../css/ReviewCard.css";

const ReviewCardSingle = () => {
    return (
        <div className="publication_filtered-container">
            <div className="publication_container">
                <div
                    className="publication_card publication_skew publication_glow"
                    style={{ backgroundImage: "url('/img/Publication/option.png')" }}
                >
                    <div className="publication_content">
                        <br />
                        <p
                        
                            id="publication_paragraph"
                            style={{ textTransform: "uppercase", textAlign: "center", fontSize: "25px" }}
                        >
                            SPACE TECHNOLOGY STUDENTS' SOCIETY
                        </p>
                        <p
                            id="publication_paragraph"
                            style={{ textTransform: "uppercase", textAlign: "center" }}
                        >
                            2024 RESEARCH PUBLICATION
                        </p>
                        <br />
                        <a href="https://drive.google.com/file/d/1VDUDCxiMqwZ1eL0SBV_bb14EqJIRVh9J/view?usp=sharing" target="_blank" rel="noopener noreferrer" style={{ textAlign: "center", justifyContent: "center" }}>
                            Read More
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ReviewCardSingle;
