import "../../css/LandingPage_Section1.css";
import React from "react";

function LandingPage_Section1() {
  return (
    <div className="landingpage-section1">
      <div className="landingpage-section1-main-container">
        <div className="landingpage-section1-title">
          SPACE TECHNOLOGY STUDENTS' SOCIETY
          <div className="landingpage-section1-byline">
            A diverse group of students sharing a common interest in space
            science and astronomy
          </div>
        </div>
      </div>
      <img
        src=" /img/landingpage/astronaut.png"
        alt=""
        className="landingpage-section1-astronaut"
      />
      {/* <img src=" /img/landingpage/landingpage-asteroid.png" alt="" className="landingpage-section1-asteroid" /> */}
      <img
        src=" /img/landingpage/landingpage-ufo.png"
        alt=""
        className="landingpage-section1-ufo"
      />
      <img
        src=" /img/landingpage/landingpage-saturn.png"
        alt=""
        className="landingpage-section1-saturn"
      />
      <img
        src=" /img/landingpage/landingpage-telescope.png"
        alt=""
        className="landingpage-section1-telescope"
      />
      {/* <img src=" /img/landingpage/landingpage-rocket.png" alt="" className="landingpage-section1-rocket" /> */}
      <div className="landingpage-section1-blob1">
        <svg
          viewBox="0 0 500 500"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          id="blobSvg"
        >
          <path fill="#ffffff">
            <animate
              attributeName="d"
              dur="5s"
              repeatCount={"indefinite"}
              values="M455,296.5Q411,343,374.5,373Q338,403,294,414Q250,425,198.5,426.5Q147,428,108,391Q69,354,77.5,302Q86,250,90,205Q94,160,116,108Q138,56,194,50Q250,44,292,74.5Q334,105,382.5,125Q431,145,465,197.5Q499,250,455,296.5Z;
              
              M459.5,311Q461,372,413,410Q365,448,307.5,453.5Q250,459,198.5,443.5Q147,428,124,382Q101,336,69.5,293Q38,250,64.5,204Q91,158,126.5,127.5Q162,97,206,62.5Q250,28,297,57.5Q344,87,368,127.5Q392,168,425,209Q458,250,459.5,311Z;
              
              M465.5,312Q465,374,412,406.5Q359,439,304.5,424.5Q250,410,201.5,413.5Q153,417,95.5,394.5Q38,372,48,311Q58,250,62,197Q66,144,106.5,108Q147,72,198.5,37Q250,2,305,31Q360,60,371,117Q382,174,424,212Q466,250,465.5,312Z;

              M432.2457,289.65727Q387.06024,329.31454,376.66731,390.39245Q366.27438,451.47036,308.13719,442.68594Q250,433.90153,200.4608,427.52916Q150.92161,421.15679,126.15631,378.39245Q101.39101,335.62811,89.04828,292.81406Q76.70554,250,84.34273,204.27438Q91.97992,158.54876,131.44072,135.53872Q170.90153,112.52868,210.45076,83.76386Q250,54.99904,310.19598,48.66635Q370.39197,42.33365,417.24426,84.2653Q464.09655,126.19694,470.76386,188.09847Q477.43117,250,432.2457,289.65727Z;

              M455,296.5Q411,343,374.5,373Q338,403,294,414Q250,425,198.5,426.5Q147,428,108,391Q69,354,77.5,302Q86,250,90,205Q94,160,116,108Q138,56,194,50Q250,44,292,74.5Q334,105,382.5,125Q431,145,465,197.5Q499,250,455,296.5Z
              "
            ></animate>
          </path>
        </svg>
      </div>
      <div className="landingpage-section1-blob2">
        <svg
          viewBox="0 0 500 500"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          id="blobSvg"
        >
          <path fill="#ffffff">
            <animate
              attributeName="d"
              dur="5s"
              repeatCount={"indefinite"}
              values="M455,296.5Q411,343,374.5,373Q338,403,294,414Q250,425,198.5,426.5Q147,428,108,391Q69,354,77.5,302Q86,250,90,205Q94,160,116,108Q138,56,194,50Q250,44,292,74.5Q334,105,382.5,125Q431,145,465,197.5Q499,250,455,296.5Z;
              
              M459.5,311Q461,372,413,410Q365,448,307.5,453.5Q250,459,198.5,443.5Q147,428,124,382Q101,336,69.5,293Q38,250,64.5,204Q91,158,126.5,127.5Q162,97,206,62.5Q250,28,297,57.5Q344,87,368,127.5Q392,168,425,209Q458,250,459.5,311Z;
              
              M465.5,312Q465,374,412,406.5Q359,439,304.5,424.5Q250,410,201.5,413.5Q153,417,95.5,394.5Q38,372,48,311Q58,250,62,197Q66,144,106.5,108Q147,72,198.5,37Q250,2,305,31Q360,60,371,117Q382,174,424,212Q466,250,465.5,312Z;

              M432.2457,289.65727Q387.06024,329.31454,376.66731,390.39245Q366.27438,451.47036,308.13719,442.68594Q250,433.90153,200.4608,427.52916Q150.92161,421.15679,126.15631,378.39245Q101.39101,335.62811,89.04828,292.81406Q76.70554,250,84.34273,204.27438Q91.97992,158.54876,131.44072,135.53872Q170.90153,112.52868,210.45076,83.76386Q250,54.99904,310.19598,48.66635Q370.39197,42.33365,417.24426,84.2653Q464.09655,126.19694,470.76386,188.09847Q477.43117,250,432.2457,289.65727Z;

              M455,296.5Q411,343,374.5,373Q338,403,294,414Q250,425,198.5,426.5Q147,428,108,391Q69,354,77.5,302Q86,250,90,205Q94,160,116,108Q138,56,194,50Q250,44,292,74.5Q334,105,382.5,125Q431,145,465,197.5Q499,250,455,296.5Z
              "
            ></animate>
          </path>
        </svg>
      </div>
      <div className="landingpage-section1-blob3">
        <svg
          viewBox="0 0 500 500"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="100%"
          id="blobSvg"
        >
          <path fill="#ffffff">
            <animate
              attributeName="d"
              dur="5s"
              repeatCount={"indefinite"}
              values="M455,296.5Q411,343,374.5,373Q338,403,294,414Q250,425,198.5,426.5Q147,428,108,391Q69,354,77.5,302Q86,250,90,205Q94,160,116,108Q138,56,194,50Q250,44,292,74.5Q334,105,382.5,125Q431,145,465,197.5Q499,250,455,296.5Z;
              
              M459.5,311Q461,372,413,410Q365,448,307.5,453.5Q250,459,198.5,443.5Q147,428,124,382Q101,336,69.5,293Q38,250,64.5,204Q91,158,126.5,127.5Q162,97,206,62.5Q250,28,297,57.5Q344,87,368,127.5Q392,168,425,209Q458,250,459.5,311Z;
              
              M465.5,312Q465,374,412,406.5Q359,439,304.5,424.5Q250,410,201.5,413.5Q153,417,95.5,394.5Q38,372,48,311Q58,250,62,197Q66,144,106.5,108Q147,72,198.5,37Q250,2,305,31Q360,60,371,117Q382,174,424,212Q466,250,465.5,312Z;

              M432.2457,289.65727Q387.06024,329.31454,376.66731,390.39245Q366.27438,451.47036,308.13719,442.68594Q250,433.90153,200.4608,427.52916Q150.92161,421.15679,126.15631,378.39245Q101.39101,335.62811,89.04828,292.81406Q76.70554,250,84.34273,204.27438Q91.97992,158.54876,131.44072,135.53872Q170.90153,112.52868,210.45076,83.76386Q250,54.99904,310.19598,48.66635Q370.39197,42.33365,417.24426,84.2653Q464.09655,126.19694,470.76386,188.09847Q477.43117,250,432.2457,289.65727Z;

              M455,296.5Q411,343,374.5,373Q338,403,294,414Q250,425,198.5,426.5Q147,428,108,391Q69,354,77.5,302Q86,250,90,205Q94,160,116,108Q138,56,194,50Q250,44,292,74.5Q334,105,382.5,125Q431,145,465,197.5Q499,250,455,296.5Z
              "
            ></animate>
          </path>
        </svg>
      </div>
    </div>
  );
}

export default LandingPage_Section1;
