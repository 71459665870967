import "../css/MemberCard.css";

export default function MemberCard({ data, isSpecial }) {
  let cardStyler = isSpecial ? "member-card-gov-gsec" : `member-card-team`;

  let socialIsClickable = (datalink) => {
    return datalink == null ? "" : "member-card-social-isclickable";
  };

  let cardBackground = "/img/team/member-card-background.jpg";
  let innerOrbit = "/img/team/inner-orbit.jpg";
  let outerOrbit = "/img/team/outer-orbit.jpg";

  return (
    <div className={`member-card ${cardStyler}`}>
      <div className="member-card-white-box" />
      <ul className="member-card-socials">
        <li className={socialIsClickable(data.facebook)}>
          <a href={data.facebook}>
            <i className="member-card-social-icon fa fa-facebook-f" />
          </a>
        </li>
        <li className={socialIsClickable(data.email)}>
          <a href={"mailto:" + data.email}>
            <i className="member-card-social-icon fa fa-envelope" />
          </a>
        </li>
        <li className={socialIsClickable(data.linkedin)}>
          <a href={data.linkedin}>
            <i className="member-card-social-icon fa fa-linkedin" />
          </a>
        </li>
      </ul>
      <div
        className="member-card-img"
        style={{ backgroundImage: `url(${cardBackground})` }}
      >
        <img src={"/img/team/" + data.img} alt={data.name} />
        <img src={innerOrbit} />
        <img src={outerOrbit} />
      </div>
      <div className="member-card-info">
        <div className="member-card-data">
          <div className="member-card-name">
            <b>{data.name}</b>
          </div>
          <div className="member-card-line"></div>
        </div>
        <div className="member-card-data">
          <div className="member-card-post">{data.position}</div>
        </div>
      </div>
    </div>
  );
}
