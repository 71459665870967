import { Link, useParams } from "react-router-dom";
import { db } from "../firebaseConfig";
import { onValue, ref, set } from "firebase/database";
import PostContainer from "../components/PostContainer";
import { useState, useEffect, useRef } from "react";
import PostDetails from "../components/PostDetails";
import "../css/Post.css";
import "../css/Loader.css";
import Redirect from "../components/Redirect";
import RelatedBlogsCard from "../components/RelatedBlogsCard";
import Loader from "../components/Loader";

export default function Post(props) {
  document.title = "Blog Post | Space Technology Students' Society";

  const [blog, setBlog] = useState();
  let { slug } = useParams();
  const [post, setPost] = useState();
  const [postId, setPostId] = useState(null);
  const [isValid, updateIsValid] = useState(true);
  const [editor, setEditor] = useState(null);
  const [initialData, setInitialData] = useState(null);
  const [related, setRelated] = useState([]);
  const sidebarRef = useRef(null);
  const [top, setTop] = useState(0);

  const write = props.write || slug === "new";

  function savePost(e, newPost) {
    if (!confirm("Are you sure you want to save?")) {
      e.preventDefault();
      return;
    }

    if (post.tags.length < 1) {
      alert("Please add atleast one tag");
      e.preventDefault();
      return;
    }

    if (post.slug[post.slug.length - 1] === "-") {
      alert("Please remove trailing hyphens from slug");
      e.preventDefault();
      return;
    }

    //check unique slug
    if (newPost) {
      for (let i = 0; i < blog.posts.length; i++) {
        if (blog.posts[i] && post.slug === blog.posts[i].slug) {
          alert("Slug exists. Please choose a unique slug.");
          e.preventDefault();
          return;
        }
      }
    }

    //save to backend

    //save in blog data
    //find latest index position
    let loc = "blog/posts/" + postId;
    set(ref(db, loc), post)
      .then(() => {
        // console.log("saved to " + loc);

        // save in post data
        set(ref(db, post.file), editor.getData())
          .then(() => {
            // console.log("saved to " + post.file);

            newPost
              ? alert("Post created successfully")
              : alert("Post updated successfully");

            window.location.href = "/blog/post/" + post.slug;
          })
          .catch((error) => {
            alert("error");
            console.log(error);

            if (newPost) {
              //delete from blog.json
              set(ref(db, loc), null)
                .then(() => {
                  console.log("deleted from " + loc);
                  // alert("deleted from " + loc);
                })
                .catch((error) => {
                  alert("error");
                  console.log(error);
                });
            }
          });
      })
      .catch((error) => {
        alert("error");
        console.log(error);
      });
  }

  useEffect(() => {
    // useEffect runs when write is true but there is no user -> (exit the callback)
    if (write && !props.user) return;

    const query = ref(db, "blog");
    setInitialData(null);
    return onValue(query, (snapshot) => {
      let data = snapshot.val();
      if (snapshot.exists()) {
        data.tags = data.tags.map((item) => {
          let count = 0;
          data.posts.forEach((post) => {
            if (post.tags.includes(item)) count++;
          });
          return {
            name: item,
            count: count,
          };
        });
        setBlog(data);
        if (slug === "new") {
          let postId = data.posts.length;
          // The above line looks like a logical error but it is not.
          // It is the way firebase return objects with consecutive numeric keys as an array or else as an object
          // If yes, the returned array length will 1 more than the max value of the keys
          //(but while "filtering" the array, they will fall down to start from 0)
          // Eg - If it has only 2 keys 0 and 3, the returned array length will be 4 and not 2 and the 1st and 2nd index will be "empty"
          // Hence, key 0 will have to be present in the database always

          let newPost = {
            image: "https://picsum.photos/200/300",
            title: "New Post",
            description:
              "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum itaque exercitationem nam eaque odit provident nobis, sint earum facere repellendus cumque accusantium doloremque harum animi unde amet quibusdam ullam maiores?",
            date: new Date(new Date().toLocaleDateString() + " 05:30:00")
              .toISOString()
              .split("T")[0],
            tags: [],
            file: "post" + postId,
            slug: "post-" + postId,
          };
          setPostId(postId);
          setPost(newPost);
          setInitialData(" ");
        } else {
          let post = data.posts.filter((item, index) => {
            if (item.slug === slug) {
              setPostId(index);
              return true;
            } else return false;
          });
          if (post.length === 0) updateIsValid(false);
          else {
            post = post[0];

            // set initial editor from database
            // this is shifted from PostContainer.js to here to avoid lag in loading of editor after other page has loaded
            const query_data = ref(db, post.file);
            onValue(query_data, (snapshot) => {
              const data = snapshot.val();
              if (snapshot.exists()) {
                setInitialData(data);
              }
            });

            setPost(post);
            let related = data.posts
              .sort((a, b) => {
                let da = new Date(a.date);
                let db = new Date(b.date);
                return db - da;
              })
              .filter((item) => {
                return (
                  item.slug != slug &&
                  item.slug != "sample-post" &&
                  post.tags.some((tag) => item.tags.includes(tag))
                );
              });
            if (related.length > 6) related = related.slice(0, 6);
            setRelated(related);
          }
        }
      }
    });
  }, [slug]);

  useEffect(() => {
    // set top value of sidebar
    if (sidebarRef.current) {
      setTop(
        sidebarRef.current.offsetHeight > window.innerHeight
          ? window.innerHeight - sidebarRef.current.offsetHeight - 10
          : 0
      );
    }
  }, [related]);

  if (!isValid)
    return (
      <>
        <h1>Post not found</h1>
        <Redirect path="/blog" time={500} />
      </>
    );

  if (write && !props.user) return <Redirect path="/blog" time={0} />;

  const titleString = (string) => {
    return string
      .split("-")
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      .join(" ");
  };

  // const rltd = {
  //   image: "https://picsum.photos/200/300",
  //   title: "Rudimentary Analysis Of The Global Orbital Space Launches 2023",
  //   date: "2023-12-15",
  // };
  // const arr = [0, 1, 2, 3, 4];

  return initialData ? (
    <div className="blog-post-page">
      <div className={"blog-post-container" + (write ? " editable" : "")}>
        <PostDetails
          blog={blog}
          post={post}
          setPost={setPost}
          write={write}
          new={slug === "new"}
        />
        <PostContainer
          post={post}
          write={write}
          setEditor={setEditor}
          new={slug === "new"}
          initialData={initialData}
        />
        <div className="blog-post-footer">
          {!write && props.user ? (
            <Link to={"/blog/post/" + post.slug + "/edit"}>Edit Post</Link>
          ) : (
            <></>
          )}
          {write && (
            <>
              {slug !== "new" ? (
                <>
                  <a
                    href={"/blog/post/" + post.slug}
                    style={{ background: "red" }}
                  >
                    Cancel (Go Back)
                  </a>
                </>
              ) : (
                <Link to="/blog" style={{ background: "red" }}>
                  Cancel (Go Back)
                </Link>
              )}
              <a
                style={{ background: "green" }}
                onClick={(e) => savePost(e, slug === "new")}
              >
                Save Post
              </a>
            </>
          )}
        </div>
      </div>
      {!write && (
        <div
          className="blog-post-sidebar"
          ref={sidebarRef}
          style={{
            top: `${top}px`,
          }}
        >
          <h3>Related Blogs</h3>
          <hr />
          <div className="blog-post-related-container">
            {related.map((val, ind) => (
              <RelatedBlogsCard
                post={val}
                line={ind + 1 != related.length}
                key={"related" + ind}
                nav={() => nav(val.slug)}
              />
            ))}
          </div>
          <h3>Popular Topics</h3>
          <hr />
          <div className="blog-tags-container">
            {blog.tags
              .sort((a, b) => b.count - a.count)
              .slice(0, 8)
              .map((item, id) => (
                <Link
                  key={id}
                  to={"/blog/" + item.name}
                  className="blog-tag-button"
                >
                  {titleString(item.name)}
                  {/* <span>{item.count ? `(${item.count})` : ""}</span> */}
                </Link>
              ))}
          </div>
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
}
