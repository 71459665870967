import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Redirect(props) {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (props.href) window.location.href = props.href;
      // 👇 Redirects to home page, note the `replace: true`
      if (props.path) navigate(props.path, { replace: true });
    }, props.time);
  }, []);

  return <h1>Redirecting...</h1>;
}
