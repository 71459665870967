import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState } from "react";
import "./css/App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LandingPage from "./pages/LandingPage";
import About from "./pages/About";
import Events from "./pages/Events";
import Team from "./pages/Team";
import LatestUpdates from "./pages/LatestUpdates";
import LatestForm from "./pages/LatestForm";
import Blog from "./pages/Blog";
import Post from "./pages/Post";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Redirect from "./components/Redirect";
import ScrollToTop from "./components/ScrollToTop";
import Publication from "./pages/Publication";
import Stac1 from "./pages/Stac1";

function App() {
	const [user, setUser] = useState(getAuth().currentUser);
	onAuthStateChanged(getAuth(), (user) => {
		setUser(user);
	});

	return (
		<BrowserRouter>
			<div className="bg-animation">
				<div id="stars"></div>
				<div id="stars2"></div>
				<div id="stars3"></div>
				<div id="stars4"></div>
			</div>
			<div className="app-container">
				<Navbar user={user} />
				<Routes>
					<Route exact path="/" element={<LandingPage />} />
					<Route exact path="/blog" element={<Blog user={user} />} />
					<Route
						path="/blog/post/:slug/edit"
						element={<Post write={true} user={user} />}
					/>
					<Route
						path="/blog/post/:slug"
						element={<Post write={false} user={user} />}
					/>
					<Route path="/blog/:tag" element={<Blog user={user} />} />
					<Route path="/about" element={<About />} />
					<Route path="/events" element={<Events />} />
					<Route path="/team" element={<Team />} />
					<Route
						path="/latest/new/:id"
						element={<LatestForm user={user} action={"New"} />}
					/>
					<Route
						path="/latest/edit/:id"
						element={<LatestForm user={user} action={"Edit"} />}
					/>
					<Route path="/latest" element={<LatestUpdates user={user} />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/publication" element={<Publication />} />
					<Route path="/stac" element={<Stac1 />} />
					<Route path="/login" element={<Login user={user} />} />
					<Route
						path="/SpaceQuiz"
						element={
							<Redirect
								href="https://unstop.com/p/fresher-space-quiz-spats-863322"
								time={50}
							/>
						}
					/>
					<Route
						path="/Stac"
						element={
							<Redirect
								href="https://docs.google.com/forms/d/e/1FAIpQLSfuJQ9Xaf27yq_VI20vhck4zUOcJRW1Kwr-apN7UeCdIsHwaA/viewform"
								time={50}
							/>
						}
					/>
					<Route
						path="/stac_nsu"
						element={
							<Redirect
								href="https://docs.google.com/forms/d/e/1FAIpQLSdRCFSO3NqwYhnp8OJVqhTOl95xPcMP07QlRew4mU8Bo-gQ6w/viewform"
								time={50}
							/>
						}
					/>
					<Route
						path="/selections"
						element={
							<Redirect
								href="https://docs.google.com/forms/d/e/1FAIpQLSdC2ysune8YeobMpn28nr3O4C1NEiaESHivpFRC4BFLio8A7Q/viewform"
								time={50}
							/>
						}
					/>
					<Route
						path="/waterrocketry"
						element={
							<Redirect href="https://forms.gle/T3myeVjFhxzgBmQW6" time={50} />
						}
					/>
					<Route path="*" element={<Redirect path="/" time={500} />} />
				</Routes>
				<Footer />
				<ScrollToTop />
			</div>
		</BrowserRouter>
	);
}

export default App;
