import React, { useState, useEffect, useRef } from "react";
import "../css/About.css";
export default function About() {
  document.title = "About | Space Technology Students' Society";

  const SLIDE_HEIGHT = 185;
  const SWIPE_THRESHOLD = 80;
  const aboutContentData = [
    {
      sidebarName: " spAts",
      heading: "spAts",
      content:
        "We are the Space Technology Student’s Society (better known as spAts), the official student body under Kalpana Chawla Space Technology Cell, which is the contact point of ISRO and IIT Kharagpur. spAts was founded when a diverse group of students sharing a common interest in space science and astronomy came together. It has since been the motto of spAts to spread awareness among the masses about the cosmos and simultaneously enhance our knowledge and understanding of space through activities and fun. \n Each individual of our Society has a thirst for knowledge and love being engaged in learning. Some lean towards the best technical stuff, some are interested towards the theoretical side of things, some love astronomy, reading, discussing current events and some love capturing the beauty of the night sky. We learn about stuff by building projects, having loads of discussions, exploring new skills and of course - dedicating towards space.",
    },

    {
      sidebarName: "FQTQ",
      heading: "FQTQ",
      content:
        "A subgroup of spAts with a rather funky name - “From Quarks to Quasars” (or FQTQ as we call it) who are deeply motivated with the theoretical aspects of the universe. We engage in more serious discussion on topics ranging from physics, astrophysics and cosmology with a greater interest in astronomy and doing stuff together. We organise regular presentations, research talks, and we love to know about emerging topics by reading, exploring, writing on our own. We have the goal to enrich our learning from the smallest of sub-atomic particles to the most distant objects ever seen with modern science.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartY, setTouchStartY] = useState(null);
  const [wheel, setWheel] = useState(true);
  const [isBottom, setIsBottom] = useState(true);
  const [isTop, setIsTop] = useState(true);

  const scrollableDivRefs = useRef([]);
  const checkScrollBottomRef = useRef();

  useEffect(() => {
    const checkScrollBottom = (index) => {
      const scrollableDiv = scrollableDivRefs.current[index];

      const scrollableHeight =
        scrollableDiv.scrollHeight - scrollableDiv.clientHeight;
      if (scrollableDiv.scrollTop === 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }

      if (scrollableDiv.scrollTop >= scrollableHeight - 0.5) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };
    checkScrollBottomRef.current = checkScrollBottom;

    scrollableDivRefs.current.forEach((ref, index) => {
      ref.addEventListener("scroll", () => checkScrollBottomRef.current(index));
    });

    return () => {
      scrollableDivRefs.current.forEach((ref, index) => {
        ref &&
          ref.removeEventListener("scroll", () =>
            checkScrollBottomRef.current(index)
          );
      });
    };
  }, []);

  const handleNext = () => {
    if (!isBottom) {
      return;
    }
    setCurrentIndex(
      currentIndex < aboutContentData.length - 1
        ? currentIndex + 1
        : currentIndex
    );
    setIsBottom(true);
    setIsTop(true);
  };

  const handlePrev = () => {
    if (!isTop) {
      return;
    }
    setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : currentIndex);
    setIsBottom(true);
    setIsTop(true);
  };

  const handleTouchStart = (event) => {
    setTouchStartY(event.touches[0].clientY);
  };

  const handleTouchMove = (event) => {
    if (!touchStartY) {
      return;
    }
    const touchEndY = event.touches[0].clientY;
    const deltaY = touchEndY - touchStartY;
    if (deltaY > SWIPE_THRESHOLD) {
      handlePrev();
      setTouchStartY(null);
    } else if (deltaY < -SWIPE_THRESHOLD) {
      handleNext();
      setTouchStartY(null);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 40) {
        handleNext();
      } else if (event.keyCode === 38) {
        handlePrev();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const handleWheel = (event) => {
    if (wheel) {
      setWheel(false);
      setTimeout(() => {
        if (event.deltaY > 0) {
          handleNext();
          setTimeout(() => {
            setWheel(true);
          }, 1500);
        } else if (event.deltaY < 0) {
          handlePrev();
          setTimeout(() => {
            setWheel(true);
          }, 1500);
        }
      }, 300);
    }
  };

  const slideStyle = {
    transform: `translateY(-${currentIndex * SLIDE_HEIGHT}vh)`,
    transition: "transform 1s ease-in-out",
  };

  return (
    <div className="about">
      <div className="about-sidebar">
        <div className="about-sidebar-content">
          {aboutContentData.map((element, index) => {
            return (
              <div
                key={index}
                className={`about-sidebar-element ${
                  currentIndex === index ? "active" : ""
                }`}
                onClick={() => {
                  setCurrentIndex(index);
                }}
              >
                <h1>{element.sidebarName}</h1>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="about-main"
        onWheel={handleWheel}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={() => setTouchStartY(null)}
      >
        <div className="about-main-content" style={slideStyle}>
          {aboutContentData.map((element, index) => (
            <div className="about-content-element" key={index}>
              <div className="about-main-heading">
                <h1>{element.heading}</h1>
              </div>
              <div
                className="about-main-para"
                ref={(ref) => (scrollableDivRefs.current[index] = ref)}
              >
                <p>{element.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* <div className="about-downarrow">
        <i class="fa fa-circle-chevron-down"></i>
      </div> */}
      <div className="about-social-media-icons">
        <div className="about-social-media-icons-link">
          <a href="https://www.facebook.com/spAtsiitkgp/" target="_aboutblank">
            <i className="fa fa-facebook"></i>
          </a>
        </div>
        <div className="about-social-media-icons-link">
          <a
            href="https://www.instagram.com/spats.iitkgp/"
            target="_aboutblank"
          >
            <i className="fa fa-instagram"></i>
          </a>
        </div>
        <div className="about-social-media-icons-link">
          <a
            href="https://www.linkedin.com/company/spats/"
            target="_aboutblank"
          >
            <i className="fa fa-linkedin"></i>
          </a>
        </div>
        <div className="about-social-media-icons-link">
          <a href="https://www.youtube.com/@spAtsiitkgp" target="_aboutblank">
            <i className="fa fa-youtube-play"></i>
          </a>
        </div>
        <div className="about-social-media-icons-link">
          <a href="https://twitter.com/spats_iitkgp" target="_aboutblank">
            <i className="fa fa-twitter"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
