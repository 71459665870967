import "../css/BlogCard.css";
import { Link, useNavigate } from "react-router-dom";

export default function BlogCard(props) {
  const post = props.post;
  const navigate = useNavigate();
  const nav = () => {
    navigate("/blog/post/" + post.slug);
  };

  return (
    <>
      <div className="blog-post-box">
        <img src={post.image} onClick={nav} />
        <div>
          <h2 className="blog-post-title" onClick={nav}>
            {post.title}
          </h2>
          <p className="blog-post-desc" onClick={nav}>
            {post.description}
          </p>
          <div className="blog-card-footer">
            <p>
              <i className="fa fa-calendar" aria-hidden="true" />
              {post.date}
            </p>
            {/* <Link to={"/blog/post/" + post.slug} className="blog-post-button">
            Read More
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </Link> */}
            <i className="fa fa-circle" aria-hidden="true" />
            <div
              className="blog-post-tags"
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {post.tags
                .sort((a, b) => a.localeCompare(b))
                .map((tag, idx) => (
                  <p className="blog-post-tag" key={idx}>
                    {tag}
                  </p>
                ))}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}
