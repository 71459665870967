import React from "react";
import "../css/ReviewCard.css";
import cardsData from "../data/publication.json";

const ReviewCard = () => {
  return (
    <div className="publication_filtered-container">
      <div className="publication_container">
        {cardsData.map((card) => (
          <div
            key={card.id}
            className="publication_card publication_skew publication_glow"
            style={{ backgroundImage: card.backgroundImage }}
          >
            <div className="publication_content">
              <h2>{card.id < 10 ? `0${card.id}` : card.id}</h2>
              <br />
              <p
                id="publication_paragraph"
                style={{ textTransform: "uppercase" }}
              >
                {card.title}
              </p>
              <br />
              <p className="publication_keywords" id="publication_keywords" >
                Authors: {" "}
                <span id="publication_keywords-white" style={{ fontFamily: "monospace", color: "white", fontSize: "15px" }}>{card.keywords}</span>
              </p>
              <a href={card.link} target="_blank" rel="noopener noreferrer">
                Read More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewCard;
