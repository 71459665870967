import { useEffect, useState } from "react";
import { onValue, ref } from "firebase/database";
import { db } from "../firebaseConfig";
import BlogEditorBox from "../components/BlogEditorBox";
import BlogViewerBox from "../components/BlogViewerBox";

export default function PostContainer(props) {
  const { post, write, setEditor, initialData } = props;

  // const [initialData, setInitialData] = useState(null);

  // useEffect(() => {
  //   if (props.new) {
  //     setInitialData("");
  //   } else {
  //     const query = ref(db, post.file);
  //     return onValue(query, (snapshot) => {
  //       const data = snapshot.val();
  //       if (snapshot.exists()) {
  //         setInitialData(data);
  //       }
  //     });
  //   }
  // }, [post]);

  return (
    <div className="blog-post-content">
      {initialData != null ? (
        write ? (
          <BlogEditorBox initialData={initialData} setEditor={setEditor} />
        ) : (
          <BlogViewerBox initialData={initialData} />
        )
      ) : (
        <></>
      )}
    </div>
  );
}
