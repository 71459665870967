import React from "react";
import "../css/Events.css";
import Slider2 from "../components/Events/Slider-Events";
function Events() {
  document.title = "Events | Space Technology Students' Society";
  return (
    <div className="Events-Div">
      <Slider2 />
    </div>
  );
}

export default Events;
